.invoice {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.invoice__title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 30px;
  margin: 0;
}
.invoice__delivery {
  font-family: Montserrat;
  font-size: 16px;
  padding-left: 0;
  padding-bottom: 30px;
  margin: 0;
  padding-bottom: 10px;
  list-style: none;
}
.invoice__delivery li {
  font-family: Montserrat;
  padding-bottom: 10px;
  font-size: 12px;
}
.invoice__delivery li strong {
  padding-left: 10px;
}
.invoice__price {
  font-size: 18px;
  margin-bottom: 30px;
}
.invoice__price strong {
  padding-left: 10px;
  color: #e94649;
  margin-bottom: 30px;
}
.invoice__items {
  border-collapse: collapse;
}
.invoice__items thead th {
  color: #000000;
  text-align: left;
  padding: 5px 15px;
  background-color: #f7f5e2;
  font-size: 12px;
  font-weight: 400;
}
.invoice__items tbody td {
  padding: 10px 15px;
  font-size: 12px;
}
.invoice__items tbody tr:nth-child(odd) {
  background-color: #e7e7e7;
}
