.button {
  align-self: center;
  display: block;
  width: 100%;
  max-width: 300px;
  background-color: #ec2626;
  color: #ffffff;
  font-size: 18px;
  font-weight: 700;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
}
