.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
.form__field {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
}
.form__label {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  padding-bottom: 10px;
}
.form__control {
  font-size: 14px;
  border-radius: 5px;
  padding: 10px 10px;
  border: 1px solid #c8c8c8;
  color: #333333;
}
.form__message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}
.form__message .message {
  margin-bottom: 0;
}

.checkbox {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.checkbox__input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox__label {
  position: relative;
  font-family: Montserrat;
  font-size: 9px;
  line-height: 14px;
  font-weight: 400;
  padding-left: 20px;
}

.checkbox__label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background-color: #e94649;
}

.checkbox__input:checked ~ .checkbox__label::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 2px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
