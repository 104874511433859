.message {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 30px;
  border-radius: 9px;
  background-color: #f7f5e2;
  border: 1px solid #dcd69d;
  padding: 10px 15px;
  font-size: 11px;
  line-height: 18px;
}
