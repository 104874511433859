.choices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 10px;
}

.choices__item {
  width: calc(33.33% - 10px);
}

.choices__control {
  display: none;
}

.choices__button {
  background-color: #e7e7e7;
  padding: 30px 5px;
  border-radius: 5px;
  font-size: 11px;
  font-weight: 400;
  text-align: center;
  color: #000000;
}

.choices__control:checked + .choices__button {
  background-color: #f7f5e2;
  border: 1px solid #dcd69d;
  color: #000000;
}
