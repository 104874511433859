.product {
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  overflow: hidden;
  height: 100%;
}

.product_preview {
  width: 100%;
  overflow: hidden;
}

.product__image {
  max-width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 9px;
}

.product__body {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  padding-bottom: 10px;
}

.product__title {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: #000000;
  padding-top: 10px;
  padding-bottom: 5px;
}

.product__desc {
  font-family: Montserrat;
  font-size: 11px;
  margin: 0;
  text-align: justify;
  padding-bottom: 10px;
}

.product__weight {
  font-family: Montserrat;
  color: #a2a2a2;
  font-size: 12px;
  margin: 0;
}

.product__price {
  font-size: 14px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.product__price strong {
  padding-left: 10px;
}

.product__footer {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
}

.product__btn {
  font-family: Montserrat;
  background-color: transparent;
  padding: 6px 15px;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  border-radius: 30px;
  border: 2px solid #ffbbbb;
  color: #ff4343;
  height: 31px;
  cursor: pointer;
}
.product__btn--quantity {
  width: 60px;
  border-color: transparent;
  color: #ffffff;
}

.product__btn:hover,
.product__btn:focus,
.product__btn:active {
  background: #ff4343;
  border-color: #ff4343;
  color: #ffffff;
}


.product__cart {
  display: flex;
  flex-direction: row;
  background: #ff4343;
  border-radius: 30px;
}
.product__quantity {
  flex: 1 0;
  width: 100%;
  background: none;
  border: none;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}
.product__quantity:hover,
.product__quantity:focus,
.product__quantity:active {
  outline: none;
  border: none;
}
