.product-list {
  display: flex;
  flex-direction: column;
}

.product-list__title {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 20px;
  border-bottom: 2px solid #000000;
  padding-bottom: 5px;
}

.product-list__category {
  margin-bottom: 15px;
}

.product-list__items {
  display: flex;
  flex-wrap: wrap;
  gap: 30px 20px;
}

.product-list__product {
  width: calc(50% - 10px);
}
