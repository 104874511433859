.page {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 100%;
  width: 100%;
  /* padding: 30px 15px; */
  background-color: #ffffff;
}

.page__outline {
  height: 50px;
  width: 100%;
  background-size: contain;
}

.page__content {
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px 23px 30px;
  width: 100%;
}
